"use client";

import {
  setAccountingFirm,
  setCollaborators,
  setCompaniesCount,
  setEntriesImportsCount,
  setMustEnterPM,
  setMustEnterPMModal,
  setUsersCount,
} from "@/redux/slices/AccountingFirmSlice";
import { deleteCompanies } from "@/redux/slices/CompanySlice";
import { setDarkmode } from "@/redux/slices/StyleSlice";
import { addToast } from "@/redux/slices/ToastSlice";
import { logout, setNewReleaseIsOpen, storeReleasesToCommunicate, storeUser } from "@/redux/slices/UserSlice";
import { store } from "@/redux/store";
export const getUser = () => {
  return store.getState().users.user;
};

export const onAuthSuccessFull = (data, dispatch, router) => {
  // clean old storage
  localStorage.removeItem("persist:root");
  dispatch(logout(null));
  dispatch(deleteCompanies(null));

  dispatch(setDarkmode(data[0].user.themePreference));
  // store new Data
  let users = data[0]["user"];
  let counts = data[0]["counts"];
  data[0]["token_ttl"] && localStorage.setItem("token_ttl", data[0]["token_ttl"]);
  users?.accountingFirm && dispatch(setAccountingFirm(users?.accountingFirm));
  dispatch(storeUser(users));
  dispatch(addToast({ legend: "Vous êtes connecté", type: "good" }));
  //  COUNTS
  dispatch(setCompaniesCount(counts?.companies));
  dispatch(setUsersCount(counts?.users));
  dispatch(setEntriesImportsCount(counts?.entryImports));

  /// Informer l'utilisateur si il doit renseigner ses informations de paiements (PM = PaymentIntent)
  dispatch(setMustEnterPM(data[0]["mustEnterPM"]));
  dispatch(setMustEnterPMModal(data[0]["mustEnterPM"]));
  dispatch(setCollaborators(data[0]["collaborators"]));

  /// Informer L'utilisateur des versions postérieures à sa dernière connexion
  dispatch(storeReleasesToCommunicate(data[0]["newReleasesCommunication"]));
  data[0]["newReleasesCommunication"]?.length > 0 && dispatch(setNewReleaseIsOpen(true));

  // cabinet supprimé
  if (data[0]["user"].accountingFirm?.deletedAt) {
    router.push("/cabinet-supprime");
    return;
  }

  //// informer l'utilisateur que sa version n'est plus en démo
  if (data[0]["user"]?.accountingFirm?.demoLimitDate < new Date()) {
  }

  /// redirection vers dossiers ou regul selon la situation
  if (data[0]["user"].accountingFirm?.blockedAt && new Date(data[0]["user"].accountingFirm?.blockedAt) < new Date()) {
    router.push("/dashboard/gestion-cabinet/facturation/regularisation");
    return;
  }

  router.push("/dashboard");
};
