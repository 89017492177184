import { onAuthSuccessFull } from "@/lib/auth";
import { baseURL } from "@/lib/http";
import { useAppDispatch } from "@/redux/store";
import axios from "axios";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

export default function AutoConnect() {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const router = useRouter();

  const login = async (abortController) => {
    setLoading(true);
    axios
      .post(
        `${baseURL}auth/refresh-token`,
        {},
        {
          withCredentials: true,
          headers: { "X-interface": "expert" },
          signal: abortController.signal,
        },
      )
      .then((resp) => {
        setLoading(false);
        if (resp.data) {
          onAuthSuccessFull(resp.data, dispatch, router);
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    let controller = new AbortController();

    login(controller);

    return () => {
      return controller.abort();
    };
  }, []);

  return (
    <>
      {/* {loading && (
        <div
          className="tile mw-400 br-3 p-7"
          style={{
            minHeight: "unset",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            filter: "drop-shadow(0 0 25px var(--color-gray2))",
            zIndex: 5,
          }}
        >
          <Shape size={150} color="infoBackground">
            <CircleLoader></CircleLoader>
          </Shape>
          <h2 className="text-center fs-150">Auto-connexion</h2>
          <p className="text-center">
            Syndemos se souvient de votre identifiant, veuillez patienter...
          </p>
        </div>
      )} */}
    </>
  );
}
